<template>
  <article
    aria-label="overdue-notification"
    class="bg-dnd_red flex flex-col items-center justify-between gap-y-5 rounded-3xl px-4 py-6 md:gap-x-5 lg:flex-row lg:rounded-[40px]"
  >
    <div
      aria-label="greeting"
      class="flex items-center justify-center gap-x-2 rounded-[32px] bg-white px-4 py-2 lg:gap-x-3"
    >
      <UiIcon name="personCircle" />
      <p class="text-dnd_red text-sm">Здравствуйте, {{ userStore.user.first_name }}!</p>
    </div>

    <p class="text-center text-base text-white lg:text-lg">
      У вас найдена просроченная оплата! Срочно внесите платёж.
    </p>

    <div>
      <UiButton
        small
        type="white"
        class="text-dnd_red w-full"
        @click="openPayPage"
      >
        Оплатить сейчас
      </UiButton>
    </div>
  </article>
</template>

<script setup>
const userStore = useUserStore();

const openPayPage = () => {
  navigateTo(`https://prostoyvopros.ru/sposobyi-oplatyi/${userStore.user.contract_number}/`, {
    open: {
      target: '_blank',
    },
    external: true,
  });
};
</script>

<style scoped></style>
